//
// Constantes de permisos disponibles
//

export class Permission {
  //Inventario
  public static read_inventario = "read_inventario";
  public static write_inventario = "write_inventario";
  public static update_inventario = "update_inventario";
  public static delete_inventario = "delete_inventario";
  public static menu_inventario = "menu_inventario";

  //Proyectos
  public static read_proyectos = "read_proyectos";
  public static write_proyectos = "write_proyectos";
  public static update_proyectos = "update_proyectos";
  public static delete_proyectos = "delete_proyectos";
  public static menu_proyectos = "menu_proyectos";

  //Usuarios
  public static read_usuarios = "read_usuarios";
  public static write_usuarios = "write_usuarios";
  public static update_usuarios = "update_usuarios";
  public static delete_usuarios = "delete_usuarios";
  public static menu_usuarios = "menu_usuarios";

  //Grupos
  public static delete_grupos = "delete_grupos";
  public static update_grupos = "update_grupos";
  public static write_grupos = "write_grupos";
  public static read_grupos = "read_grupos";
  public static menu_grupos = "menu_grupos";

  //Departamentos
  public static read_departamentos = "read_departamentos";
  public static write_departamentos = "write_departamentos";
  public static update_departamentos = "update_departamentos";
  public static delete_departamentos = "delete_departamentos";
  public static menu_departamentos = "menu_departamentos";

  //Proveedores
  public static delete_proveedores = "delete_proveedores";
  public static update_proveedores = "update_proveedores";
  public static write_proveedores = "write_proveedores";
  public static read_proveedores = "read_proveedores";
  public static menu_proveedores = "menu_proveedores";

  //Roles
  public static read_roles = "read_roles";
  public static write_roles = "write_roles";
  public static update_roles = "update_roles";
  public static delete_roles = "delete_roles";
  public static menu_roles = "menu_roles";

  //Incidencias
  public static delete_incidencias = "delete_incidencias";
  public static update_incidencias = "update_incidencias";
  public static write_incidencias = "write_incidencias";
  public static read_incidencias = "read_incidencias";
  public static menu_incidencias = "menu_incidencias";

  //Tareas
  public static read_tareas = "read_tareas";
  public static write_tareas = "write_tareas";
  public static update_tareas = "update_tareas";
  public static delete_tareas = "delete_tareas";
  public static menu_tareas = "menu_tareas";

  //Indencias Menu
  public static read_incidencias_menu = "read_incidencias_menu";

  //Tareas Menu
  public static read_tareas_menu = "read_tareas_menu";

  //Proyectos prioridades
  public static read_proyecto_prioridades = "read_proyecto_prioridades";
  public static write_proyecto_prioridades = "write_proyecto_prioridades";
  public static update_proyecto_prioridades = "update_proyecto_prioridades";
  public static delete_proyecto_prioridades = "delete_proyecto_prioridades";
  public static menu_proyecto_prioridades = "menu_proyecto_prioridades";

  //Proyecto fases
  public static read_proyecto_fases = "read_proyecto_fases";
  public static write_proyecto_fases = "write_proyecto_fases";
  public static update_proyecto_fases = "update_proyecto_fases";
  public static delete_proyecto_fases = "delete_proyecto_fases";
  public static menu_proyecto_fases = "menu_proyecto_fases";

  //Incidencias prioridades
  public static read_incidencias_prioridades = "read_incidencias_prioridades";
  public static write_incidencias_prioridades = "write_incidencias_prioridades";
  public static update_incidencias_prioridades =
    "update_incidencias_prioridades";
  public static delete_incidencias_prioridades =
    "delete_incidencias_prioridades";
  public static menu_incidencias_prioridades = "menu_incidencias_prioridades";

  //Incidencias categorias
  public static read_incidencias_categorias = "read_incidencias_categorias";
  public static write_incidencias_categorias = "write_incidencias_categorias";
  public static update_incidencias_categorias = "update_incidencias_categorias";
  public static delete_incidencias_categorias = "delete_incidencias_categorias";
  public static menu_incidencias_categorias = "menu_incidencias_categorias";

  //incidencias estados
  public static read_incidencias_estados = "read_incidencias_estados";
  public static write_incidencias_estados = "write_incidencias_estados";
  public static update_incidencias_estados = "update_incidencias_estados";
  public static delete_incidencias_estados = "delete_incidencias_estados";
  public static menu_incidencias_estados = "menu_incidencias_estados";

  //Tareas Estados
  public static read_tareas_estados = "read_tareas_estados";
  public static write_tareas_estados = "write_tareas_estados";
  public static update_tareas_estados = "update_tareas_estados";
  public static delete_tareas_estados = "delete_tareas_estados";
  public static menu_tareas_estados = "menu_tareas_estados";

  //Tareas tipos
  public static read_tareas_tipos = "read_tareas_tipos";
  public static write_tareas_tipos = "write_tareas_tipos";
  public static update_tareas_tipos = "update_tareas_tipos";
  public static delete_tareas_tipos = "delete_tareas_tipos";
  public static menu_tareas_tipos = "menu_tareas_tipos";

  //Chat incidencia
  public static read_chat = "read_chat";
  public static write_chat = "write_chat";

  public static read_notificaciones = "read_notificaciones";
  public static menu_notificaciones = "menu_notificaciones";

  public static read_menu_administracion = "read_menu_administracion";
  public static read_administacion_proyectos = "read_administacion_proyectos";
  public static read_administacion_tareas = "read_administacion_tareas";
  public static read_administacion_incidencias =
    "read_administacion_incidencias";
}
