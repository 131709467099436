import { Permission } from "@/shared/permission";
import { UtilsString } from "@/utils/utils-string";
import { MenuItem } from "./menu-item";
// Cada opción tendrá indicado:
// - ó el "text" con el texto a mostrar (si no se quiere usar multiidioma)
export const MenuOptions: MenuItem[] = [
  {
    icon: "",
    icon_fntaws: "fad fa-home-lg-alt",
    text: "Inicio",
    color: "#673ab7",
    link: "/main/home",
    permission: UtilsString.Empty,
    children: [],
  },
  {
    icon: "",
    icon_fntaws: "fad fa-inventory",
    text: "Inventario",
    color: "#FF917D",
    link: "/main/inventario/treeview",
    permission: Permission.menu_inventario,
    children: [],
  },
  {
    icon: "",
    icon_fntaws: "fad fa-file-word",
    text: "Proyectos",
    color: "#FFC058",
    link: "/main/proyectos/kanban",
    permission: Permission.menu_proyectos,
    children: [],
  },
  {
    icon: "",
    icon_fntaws: "fad fa-ticket-alt",
    text: "Incidencias",
    color: "#4C6EF5",
    link: "/main/incidencias",
    permission: Permission.read_incidencias_menu,
    children: [],
  },
  {
    icon: "",
    icon_fntaws: "fad fa-ballot-check",
    text: "Tareas",
    color: "#FFA757",
    link: "/main/tareas",
    permission: Permission.read_tareas_menu,
    children: [],
  },
  {
    icon: "",
    icon_fntaws: "fad fa-users-cog",
    text: "Administración",
    color: "#E9BBA2",
    link: "/main/administracion/",
    permission: Permission.read_menu_administracion,
    children: [
      {
        icon: "",
        icon_fntaws: "fad fa-users",
        text: "Usuarios",
        color: "#FFB2FF",
        link: "/main/usuarios/lista",
        permission: Permission.menu_usuarios,
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-users-class",
        text: "Grupos",
        color: "#F783AC",
        link: "/main/grupos/lista",
        permission: Permission.menu_grupos,
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-building",
        text: "Departamentos",
        color: "#4C6EF5",
        link: "/main/departamentos/lista",
        permission: Permission.menu_departamentos,
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-user-hard-hat",
        text: "Proveedores",
        color: "#82C91E",
        link: "/main/empresas/lista",
        permission: Permission.menu_proveedores,
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-paper-plane",
        text: "Notificaciones",
        color: "#FD7E14",
        link: "/main/notificaciones/",
        permission: Permission.menu_notificaciones,
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-user-tag",
        text: "Roles",
        color: "#BE4BDB",
        link: "/main/rol/lista/",
        permission: Permission.menu_roles,
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-file-word",
        text: "Proyectos",
        color: "#A9AEB2",
        link: "",
        permission: Permission.read_administacion_proyectos,
        children: [
          {
            icon: "",
            icon_fntaws: "fad fa-sort-numeric-up",
            text: "Prioridades",
            color: "#15AABF",
            link: "/main/administracion/proyectos/prioridades/lista",
            permission: Permission.menu_proyecto_prioridades,
            children: [],
          },
          {
            icon: "",
            icon_fntaws: "fad fa-headset",
            text: "Fases",
            color: "#A9AEB2",
            link: "/main/administracion/proyectos/fases/lista",
            permission: Permission.menu_proyecto_fases,
            children: [],
          },
        ],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-exclamation-triangle",
        text: "Incidencias",
        color: "#BE4BDB",
        link: "",
        permission: Permission.read_administacion_incidencias,
        children: [
          {
            icon: "",
            icon_fntaws: "fad fa-sort-numeric-up",
            text: "Prioridades",
            color: "#15AABF",
            link: "/main/administracion/incidencias/prioridades/lista",
            permission: Permission.menu_incidencias_prioridades,
            children: [],
          },
          {
            icon: "",
            icon_fntaws: "fad fa-headset",
            text: "Estados",
            color: "#A9AEB2",
            link: "/main/administracion/incidencias/estados/lista",
            permission: Permission.menu_incidencias_estados,
            children: [],
          },
          {
            icon: "",
            icon_fntaws: "fad fa-tags",
            text: "Categorías",
            color: "#4C6EF5",
            link: "/main/administracion/incidencias/categorias/lista",
            permission: Permission.menu_incidencias_categorias,
            children: [],
          },
        ],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-tasks",
        text: "Tareas",
        color: "#FFA757",
        link: "",
        permission: Permission.read_administacion_tareas,
        children: [
          {
            icon: "",
            icon_fntaws: "fad fa-sort-numeric-up",
            text: "Estados",
            color: "#15AABF",
            link: "/main/administracion/tareas/estados/lista",
            permission: Permission.menu_tareas_estados,
            children: [],
          },
          {
            icon: "",
            icon_fntaws: "fad fa-headset",
            text: "Tipos",
            color: "#A9AEB2",
            link: "/main/administracion/tareas/tipos/lista",
            permission: Permission.menu_tareas_tipos,
            children: [],
          },{
            icon: "",
            icon_fntaws: "fab fa-buromobelexperte",
            text: "Plantillas",
            color: "#B93735",
            link: "/main/administracion/tareas/plantillas/lista",
            permission: Permission.menu_tareas_tipos,
            children: [],
          },
        ],
      },
    ],
  },
  {
    icon: "",
    icon_fntaws: "fad fa-sign-out-alt",
    text: "Cerrar sesión",
    color: "#C6C6C5",
    permission: UtilsString.Empty,
    link: "/main/cerrar_session",
    children: [],
  },
];
