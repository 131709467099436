

































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ssmPermissionService } from "@/shared/services/PermissionService";
import LayoutMenuModule from "@/store/modules/layout-menu-module";
import { MenuItem } from "../Menu/menu-item";
import { UtilsString } from "@/utils/utils-string";
import { RouterNames } from "@/router/routernames";

@Component({
  components: {
    MenuOption: () => import("./option/menu-option.vue"),
    MenuGroup: () => import("./group/menu-group.vue"),
  },
})
export default class MenuRoot extends Vue {
  @Prop({ default: [] }) public fullMenuOptions!: MenuItem[];

  private menu: MenuItem[] = [];

  public get drawer(): boolean {
    return LayoutMenuModule.visible;
  }

  public get stilo() {
    return "height:" + this.altura + "px;overflow-x: hidden;";
  }

  public get altura() {
    return this.$vuetify.breakpoint.height - 132;
  }

  public set drawer(newtoggle: boolean) {
    LayoutMenuModule.setVisible(newtoggle);
  }

  public created() {
    // Creamos el menú según los permisos del usuarios
    this.createUserMenu();
  }

  public goToHome() {
    this.$router.push({ name: RouterNames.Home });
  }
  ///
  /// Pasar del menú completo solo las opciones sobre las que se tiene permiso
  ///
  private createUserMenu() {
    this.menu = [];
    // Recorremos las opciones raiz, y recursivamente se irán generando el resto de opciones según los permisos
    for (const item of this.fullMenuOptions) {
      this.createUserMenuOpcion(item);
    }
  }

  private createUserMenuOpcion(item: MenuItem, parent?: MenuItem) {
    if (this.hasPermiso(item.permission)) {
      // Creamos una copia de la opción
      const newItem: MenuItem = this.newMenuItem(item);

      if (this.hasSubmenuOptions(item)) {
        // Añadimos sus opciones de submenu
        for (const child of item.children) {
          this.createUserMenuOpcion(child, newItem);
        }
      }

      // Añadimos la opción si procede
      this.AddUserMenuOpcion(newItem, parent);
    }
  }
  private AddUserMenuOpcion(newItem: MenuItem, parent?: MenuItem) {
    // Solo añadiremos la opción si tiene ruta
    // o si tiene nodos hijos
    // Es decir, un nodo base (sin ruta) que no tenga hijos no debe aparecer porque no permite al usuario ir a ningún sitio
    if (
      !UtilsString.IsNullOrWhiteSpace(newItem.link) ||
      this.hasSubmenuOptions(newItem)
    ) {
      // Añadimos el item como raíz o como hijo según sea el caso
      if (parent != null && parent.children != null) {
        parent.children.push(newItem);
      } else {
        this.menu.push(newItem);
      }
    }
  }

  private hasPermiso(permiso: string) {
    // Tienes permiso para ver una opción si:
    // - no se le configura permiso a la opción
    // - o si el permiso configurado está en tu lista de permisos de usuario
    const result =
      UtilsString.IsNullOrWhiteSpace(permiso) ||
      ssmPermissionService.hasPermission(permiso);

    return result;
  }

  private hasSubmenuOptions(item: MenuItem): boolean {
    return item.children != null && item.children.length > 0;
  }

  private newMenuItem(item: MenuItem): MenuItem {
    const newItem = new MenuItem();
    newItem.icon = item.icon;
    newItem.text = item.text;
    newItem.link = item.link;
    newItem.color = item.color;
    newItem.icon_fntaws = item.icon_fntaws;
    newItem.permission = item.permission;
    newItem.children = [];
    return newItem;
  }
}
